<template>
   <div>子账户流量池</div>
</template>

<script>
export default {
  name: "directFlowPool"
}
</script>

<style scoped>

</style>